import { selectEntity } from "state/selectors/data";
import { selectSettingsId } from "cms/state/selectors/cms";

export function linkRefPath(type, id) {
    if (type === "cms.nav-links") {
        return id;
    }
    return ["link_ref", id].join(".");
}

const linkRefKeys = [
    "type",
    "resource_id",
    "label",
    "url",
    "link_anchor",
    "config_key",
    "custom_label",
    "custom_anchor",
];

function filterKeys(obj, keys) {
    return Object.keys(obj).reduce((acc, key) => {
        if (keys.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

export function getLinkRef(type, data) {
    if (type === "cms.nav-links") {
        return filterKeys(data, linkRefKeys);
    }
    return data?.link_ref || {};
}

export function setLinkRef(type, linkRef) {
    if (type === "cms.nav-links") {
        if (linkRef === null) return {};
        return { ...filterKeys(linkRef, linkRefKeys) };
    }
    return { link_ref: linkRef };
}

function defaultLinkLabel(store, type, data, linkRef) {
    if (linkRef?.type === "sitemap") {
        const link = selectEntity(store, "cms.sitemap", linkRef?.resource_id);
        return link?.title || linkRef?.label;
    }

    const action = data?.link_action;

    if (["call", "email"].includes(action)) {
        const settings = selectEntity(
            store,
            "cms.settings",
            selectSettingsId(store)
        );
        if (action === "call") return settings?.contact?.phone;
        if (action === "email") return settings?.contact?.email?.email;
    }

    return null;
}

function selectValueStoreEntryByKey(store, key) {
    const entries = store?.data?.["cms.value-store"] || {};
    return Object.values(entries).find((entry) => entry.key === key);
}

function addAnchor(url, anchor) {
    return [url, anchor].filter(Boolean).join("#");
}

function defaultLinkUrl(store, type, data, linkRef) {
    const ref = linkRef || {};

    if (ref.type === "sitemap") {
        const link = selectEntity(store, "cms.sitemap", linkRef?.resource_id);
        const sectionRef = selectEntity(
            store,
            "cms.sitemap.sections",
            linkRef?.section_id
        );

        const anchor = ref.custom_anchor || sectionRef.slug || ref.link_anchor;

        if (!ref.slug && ref.url) {
            const [url, urlAnchor] = ref.url?.split("#") || [];
            return addAnchor(url, anchor);
        }

        if (!link.slug) return null;

        return addAnchor(`/${link.slug}`, anchor);
    }
    if (ref.type === "config") {
        const configEntry = selectValueStoreEntryByKey(store, ref.config_key);
        if (!configEntry?.value) return ref.url;
        return configEntry?.value;
    }

    if (ref.type === "media") {
        const media = selectEntity(store, "cms.media", ref.resource_id);
        return media?.file ? `/download/${media.file}` : ref.url;
    }

    const action = data?.link_action;

    if (["call", "email"].includes(action)) {
        const settings = selectEntity(
            store,
            "cms.settings",
            selectSettingsId(store)
        );
        if (action === "call") return settings?.contact?.phone;
        if (action === "email") return settings?.contact?.email?.email;
    }

    return null;
}

export function selectLink(store, type, id) {
    const data = selectEntity(store, type, id);
    const linkRef = getLinkRef(type, data);
    let canOverrideLink = true;
    let broken = false;

    let label = [data?.link_label, linkRef?.custom_label].filter(Boolean)[0];
    let defaultLabel = defaultLinkLabel(store, type, data, linkRef);

    let url = data?.link;
    let defaultUrl = defaultLinkUrl(store, type, data, linkRef);

    if (linkRef?.type) {
        if (linkRef.type === "external") {
            canOverrideLink = true;
            url = linkRef.url;
        } else {
            url = defaultUrl;
            canOverrideLink = false;
            if (!url) broken = true;
        }
    }

    if (data.enable_popup) {
        canOverrideLink = false;
        defaultUrl = "Otwórz ten element jako popup";
    }

    return { label, defaultLabel, url, defaultUrl, canOverrideLink, broken };
}
