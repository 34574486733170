import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field";
import { useData } from "state/hooks";
import LinkForm from "playground/cms/Link/LinkForm";

const linkActions = ["link", "modal"];

const urlActions = ["link", "modal", "call", "email", "anchor"];

const urlLabels = {
    call: "Nr telefonu",
    email: "Adres email",
    anchor: "Kotwica",
};
const paramActions = ["book", "book-room"];

const paramLabels = {
    book: "ID oferty w systemie rezerwacji",
    "book-room": "ID pokoju w systemie rezerwacji",
};

const defaultAction = "link";

const urlFields = {
    "cms.nav-links": "url",
    "cms.links": "link",
};

const labelFields = {
    "cms.nav-links": "label",
    "cms.links": "link_label",
};

export function ItemFormLink(props) {
    const { type } = props;

    const [showAllFields, setShowAllFields] = useState(false);

    const data = useData(props);
    const action = data?.link_action || defaultAction;

    const isVisible = (field) => {
        return !!data[field] || showAllFields;
    };

    return (
        <div className={"pad-md rows gap-sm"}>
            <LinkForm {...props} />

            {type === "cms.links" && (
                <Field id={"headline"} label={"Podtytuł"} fieldType={"text"} />
            )}
            {["cms.links", "cms.nav-links"].includes(type) && (
                <Field id={"icon"} label={"Icon"} fieldType={"icon"} />
            )}

            {isVisible("tooltip_text") && (
                <Field id={"tooltip_text"} label={"tooltip_text"} />
            )}

            {isVisible("event_param") && paramActions.includes(action) && (
                <Field
                    id={"event_param"}
                    label={paramLabels[action] || "event_param"}
                />
            )}

            <div onClick={() => setShowAllFields(true)}>show all</div>
        </div>
    );
}

ItemFormLink.propTypes = {};

ItemFormLink.defaultProps = {};

export default ItemFormLink;
