import React from "react";
import { FormSection } from "../../Form";

import uuid from "uuid/v4";
import TextField from "components/fields/text/TextField";
import IconButton from "components/IconButton";

//import PropTypes from 'prop-types';

export function BookingParams(props) {
    const { value, onChange } = props;

    const add = () => {
        onChange([...value, { id: uuid(), from: null, to: null }]);
    };
    const change = (id, key, v) => {
        onChange(
            value.map((item) => (item.id === id ? { ...item, [key]: v } : item))
        );
    };
    const remove = (id) => {
        onChange(value.filter((item) => item.id !== id));
    };
    return (
        <FormSection
            name={"Parametry rezerwacji"}
            action={
                <div className={"cols"}>
                    <IconButton icon={"mui-add"} onClick={add} />
                </div>
            }
        >
            <div className={"rows gap-sm w-md"}>
                {value && value.length ? (
                    value.map((item, index) => (
                        <div className={"cols cols-middle grow gap-sm"}>
                            <div className={"cols cols-2 grow gap-xs"}>
                                <TextField
                                    onChange={(v) => change(item.id, "name", v)}
                                    value={item.name}
                                    label={"Name"}
                                    size={"small"}
                                />
                                <TextField
                                    onChange={(v) =>
                                        change(item.id, "value", v)
                                    }
                                    value={item.value}
                                    label={"Value"}
                                    size={"small"}
                                />
                            </div>
                            <IconButton
                                icon={"mui-close"}
                                onClick={() => remove(item.id)}
                            />
                        </div>
                    ))
                ) : (
                    <div className={"o-60"}>Nie określono parametrów</div>
                )}
            </div>
        </FormSection>
    );
}

BookingParams.propTypes = {};

BookingParams.defaultProps = {};

export default BookingParams;
