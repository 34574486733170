import React from "react";
//import PropTypes from 'prop-types';

import Media from "./Media";
import FormSection from "./FormSection";

import { useActions, useApiActions, useRouteParam } from "state/hooks";
import { handleUploadCallback } from "state/actions/upload";
import { openMediaSelect } from "state/actions/media";
import { SortableIterator } from "components/dnd";
import { useMedia } from "containers/media";
import DefaultFilePreview from "components/file/FilePreview";
import MediaThumbnail from "./MediaThumbnail";
import List from "containers/List";

const Iterator = SortableIterator(MediaThumbnail);

export function MediaContainer(props) {
    const { onChange, value, entityType, entityId, id, variant } = props;
    const path = [entityType, entityId, id].join("/");

    const { onUpload } = useApiActions({ onUpload: handleUploadCallback });
    const { onOpenLibrary } = useActions({ onOpenLibrary: openMediaSelect });
    const [, setPreview] = useRouteParam("preview");

    const { Render: FilePreview } = useMedia();

    const open = () => onOpenLibrary(path);
    const remove = (id) => onChange(value.filter((item) => item !== id));
    const sortEnd = (result) => onChange(result);
    const upload = (files) => {
        const cb = (media) => {
            console.log("Uploaded", media);
            onChange([...value, media.id]);
        };
        files.map((file) => onUpload(cb, "cms.media", path, file));
    };

    const FieldComponent = variant === "form-section" ? FormSection : Media;

    const list = (
        <List
            className={"media-field-grid"}
            type={"cms.media"}
            items={value}
            onRemove={remove}
            onSortEnd={sortEnd}
            onClick={setPreview}
            Iterator={Iterator}
            FilePreview={FilePreview || DefaultFilePreview}
        />
    );

    return (
        <FieldComponent
            {...props}
            count={value.length}
            isEmpty={!value.length}
            onUpload={upload}
            onRemove={remove}
            onOpenLibrary={open}
            onSortEnd={sortEnd}
            onShowPreview={setPreview}
            FilePreview={DefaultFilePreview || FilePreview}
            Iterator={Iterator}
        >
            {list}
        </FieldComponent>
    );
}

MediaContainer.propTypes = {};

MediaContainer.defaultProps = {
    value: [],
};

export default MediaContainer;
