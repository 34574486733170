import React, { useState } from "react";
//import PropTypes from 'prop-types';

import Field from "containers/Field/Field";
import { FormLayout } from "playground/cms/Form";
import { useData } from "state/hooks";
import IconButton from "components/IconButton";
import Select from "components/fields/select/Select";
import TextField from "components/fields/text/TextField";
import { CircularProgress } from "@material-ui/core";
import Icon from "components/Icon";
import BookingParams from "./BookingParams";
import Popover from "components/Popover";

function Integration(props) {
    const { id, onReload, isPending, isEmpty } = props;
    const data = useData({ type: "cms.integrations", id });
    const { name, type } = data;

    const onClick = () => {
        if (type === "idosell") {
            window.open(`https://engine${data?.account_id}.idobooking.com`);
        }
    };
    return (
        <div className={"cols cols-middle gap-px"}>
            {isEmpty && !isPending ? (
                <div className={"cols cols-middle gap-xs text-warning"}>
                    <Icon>mui-warning</Icon>
                    <div>{type}</div>
                </div>
            ) : (
                <div onClick={onClick}>{name}</div>
            )}
            {isPending ? (
                <CircularProgress size={"16px"} />
            ) : (
                <>
                    {onReload && (
                        <IconButton
                            size={"small"}
                            icon={"mui-refresh"}
                            onClick={onReload}
                        />
                    )}
                </>
            )}
        </div>
    );
}

function SelectField(props) {
    const {
        isValid,
        integrationId,
        isPending,
        isEmpty,
        onPaste,
        supportsApi,
        ...rest
    } = props;
    const [asText, setAsText] = useState(false);
    let textInput = !isValid || !integrationId || isEmpty || !supportsApi;
    if (asText) textInput = true;

    let helperText = null;
    if (supportsApi) {
        if (!isValid)
            helperText = `Id ${rest.value} nie jest zgodne z wybranym systemem rezerwacji`;
        if (isEmpty) helperText = "Brak danych z systemu rezerwacji";
        if (isPending) helperText = "Pobieranie danych z systemu rezerwacji";
    }

    const handlePaste = (e) => {
        if (!onPaste) return;
        e.preventDefault();
        rest.onChange(onPaste(e));
    };

    return (
        <div className={"rows gap-xs"}>
            <div className={"cols cols-top"}>
                {textInput ? (
                    <TextField
                        value={rest.value}
                        onChange={rest.onChange}
                        label={props.label}
                        disabled={isPending}
                        helperText={helperText}
                        onPaste={handlePaste}
                    />
                ) : (
                    <Select
                        {...rest}
                        value={isValid ? props.value : null}
                        nullable={true}
                        disabled={isPending}
                        helperText={helperText}
                    />
                )}
                {integrationId && supportsApi && (
                    <IconButton
                        onClick={() => setAsText(!textInput)}
                        icon={textInput ? "mui-search" : "mui-edit_square"}
                    />
                )}
            </div>
        </div>
    );
}

function ParamsInfo(props) {
    const { booking_params } = useData(props);
    if (!booking_params || !booking_params.length) return null;

    const params = booking_params.reduce((acc, param) => {
        const { name, value } = param;
        acc.push([name, value].join(": "));
        return acc;
    }, []);

    return (
        <div>
            Parametry rezerwacji: <strong>{params.join(", ")}</strong>
        </div>
    );
}

export function BookingIntegration(props) {
    const {
        integrationId,
        onReload,
        list,
        isPending,
        isEmpty,
        onPaste,
        supportsApi,
    } = props;
    return (
        <FormLayout.Section
            name={"Rezerwacja"}
            action={
                integrationId ? (
                    <Integration
                        id={integrationId}
                        onReload={supportsApi ? onReload : null}
                        list={list}
                        isPending={isPending}
                        isEmpty={isEmpty && supportsApi}
                    />
                ) : (
                    <div className={"o-30"}>
                        Nie połączono z systemem rezerwacji
                    </div>
                )
            }
            isPending={true}
        >
            <div className={"rows gap-sm"}>
                <div className={"cols cols-middle gap-sm"}>
                    <div className={"grow"}>
                        <Field
                            {...props}
                            label={"System rezerwacji"}
                            id={"external_id"}
                            FieldComponent={SelectField}
                        />
                    </div>
                    <Popover
                        trigger={({ onClick }) => (
                            <IconButton
                                onClick={onClick}
                                tooltip={"Parametry rezerwacji"}
                                icon={"mui-list"}
                            />
                        )}
                        content={() => (
                            <Field
                                id={"booking_params"}
                                FieldComponent={BookingParams}
                            />
                        )}
                    />
                </div>
                <ParamsInfo {...props} />
                {props.children}
            </div>
        </FormLayout.Section>
    );
}

BookingIntegration.propTypes = {};

BookingIntegration.defaultProps = {};

export default BookingIntegration;
